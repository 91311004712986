import React from "react";

import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import { BrowserRouter as Router, Route, Switch, withRouter } from "react-router-dom";
import "./styles.scss";
import { CSSTransition, TransitionGroup } from "react-transition-group";

// import AllPosts from "./components/requestblog/AllPosts.js";
// import BlogView from "./pages/BlogView.js";
import BinPage from "./pages/binPage";
// import TermsPage from "./pages/termsPage";
// import BlogIndexPage from "pages/BlogIndex.js";

// import { hydrate, render } from "react-dom";

const trackingId = "G-4QELF143RK";
ReactGA.initialize(trackingId);
ReactGA.pageview(window.location.pathname + window.location.search);
ReactGA.set({
  config: trackingId,
  js: new Date(),
});

const AnimatedSwitch = withRouter(({ location }) => {
  window.scrollTo(0, 0);
  document.getElementsByTagName("html")[0].style.overflow = "visible";
  return (
    <TransitionGroup>
      <CSSTransition key={location.pathname} classNames="slide slide" timeout={10}>
        <Switch>
          <Route exact path="/" component={BinPage} />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
});
// ReactDOM.render(
//   <Router>
//     <AnimatedSwitch />
//   </Router>,
//   document.getElementById("root")
// );

const App = (
  <Router>
    <AnimatedSwitch />
  </Router>
);

const rootElement = document.getElementById("root");

if (rootElement?.hasChildNodes()) {
  ReactDOM.hydrate(App, rootElement);
} else {
  ReactDOM.render(App, rootElement);
}

if (module.hot) {
  module.hot.accept();
}
